import router from "@/router";
import store from "@/store"

// 导航守卫
router.beforeEach((to, from, next) => {
  console.log("导航守卫",to)
  window,scrollTo(0,0);
  const token = store.state.userInfo.tokenValue
  const url = to.path
  // const whiteList = ['/', '/second', '/secondDetails', '/secondVideo', '/trainingCamp', '/trainingCampDetails', '/login'] 
  const whiteList = ['/', '/error', '/trainingCamp', '/login']
  // 已登录,去登录页
  if (token && url === '/login') {
    next('/')
  }
  // 未登录,去白名单之外的页面
  if (!token && !whiteList.includes(url)) {
    return next('/login')
    //from()
    //next('/')
    //store.commit('setWxLoginDialogVisible', true)
    // next(`/login?redirect=${router.currentRoute.fullPath}`)
  }
  next()
})