import service from "@/api/http";

export default {
  
}
// 热门岗位
export function openRedProfession() {
  return service({
    url: '/open/redProfession',
    method: 'GET',
  })
}

// 热门行业
export function openRedIndustry() {
  return service({
    url: '/open/redIndustry',
    method: 'GET',
  })
}

// 所有行业
export function openAllIndustry() {
  return service({
    url: '/open/allIndustry',
    method: 'GET',
  })
}

// 知识库列表
export function openKnowledgeList(professionId) {  
  return service({
    url: '/open/knowledgeList' + (professionId ? '?professionId=' + professionId : ''),
    method: 'GET',
  })
}

// 岗位分页
export function openProfessionPage(current, size, industryId, queryType, searchKey) {
  return service({
    url: `/open/professionPage?current=${current ? current : ''}&size=${size ? size : ''}&industryId=${industryId ? industryId : ''}&queryType=${queryType ? queryType : ''}&searchKey=${searchKey ? searchKey : ''}`,
    method: 'GET',
  })
}

// 岗位详情
export function openProfessionInfo(id) {
  return service({
    url: `/open/professionInfo/${id}`,
    method: 'GET',
  })
}

//微信二维码登录
export function loginapprcode(source) {
  return service({
      url: '/thirdLogin/renderUrl/' + source,
      method: 'GET'
  })
}

//手机第三方登录发送验证码
// export function sendcode(uuid,phone) {
//   return service({
//       url: '/msg/thirdBindPhone?uuid='+uuid+"&phone="+phone,
//       method: 'GET'
//   })
// }
export function sendcode(phone) {
  return service({
      url: '/msg/loginPhone?phone='+phone,
      method: 'GET'
  })
}

//第三方登录绑定手机
export function loginPhone(uuid,phone,code) {
  return service({
      url: '/thirdLogin/thirdBindPhone?uuid='+uuid+"&phone="+phone+"&code="+code,
      method: 'GET'
  })
}

//手机号密码登录
export function loginPassword(data) {
  return service({
      url: '/login',
      method: 'POST',
      data
  })
}


// 编辑个人资料
export function setUserInfo(data) {
  return service({
    token: true,
    url: '/userInfo',
    method: 'POST',
    data
  })
}

// 查看个人资料
export function getUserInfo() {
  return service({
    token: true,
    url: '/userInfo',
    method: 'GET',
  })
}

// 订阅知识库
export function operateSubscribe(knowledgeId) {
  return service({
    token: true,
    url: `/operate/subscribe?knowledgeId=${knowledgeId}`,
    method: 'GET',
  })
}

// 取消订阅知识库
export function operateCancelSubscribe(knowledgeId) {
  return service({
    token: true,
    url: `/operate/cancelSubscribe?knowledgeId=${knowledgeId}`,
    method: 'GET',
  })
}

// 知识库详情
export function openKnowledgeInfo(id) {
  return service({
    token: true,
    url: `/open/knowledgeInfo/${id}`,
    method: 'GET',
  })
}

// 阅读文章
export function operateReadArticle(articleId) {
  return service({
    token: true,
    url: `/operate/readArticle?articleId=${articleId}`,
    method: 'GET',
  })
}

// 账单
export function billPage() {
  return service({
    token: true,
    url: `/bill/page`,
    method: 'GET',
  })
}

// 订阅知识库列表
export function userInfoSubscribeList(current, size, professionId,industryId) {
  return service({
    token: true,
    // url: `/userInfo/subscribeList?current=${current}&size=${size}&professionId=${professionId}&industryId=${industryId}`,
    url: `/userInfo/subscribeList?current=${current}&size=${size}&professionId=${professionId}`,
    method: 'GET',
  })
}

// 个人消息分页
export function messagePage(current, size) {
  return service({
    token: true,
    url: `/message/page?current=${current}&size=${size}`,
    method: 'GET',
  })
}

// 查看文章
export function operateArticleContent(articleId) {
  return service({
    token: true,
    url: `/operate/articleContent?articleId=${articleId}`,
    method: 'GET',
  })
}

// 钱包
export function userInfoAmount() {
  return service({
    token: true,
    url: `/userInfo/amount`,
    method: 'GET',
  })
}

// 解锁模块
export function operatePayModule(moduleId) {
  return service({
    token: true,
    url: `/operate/payModule?moduleId=${moduleId}`,
    method: 'GET',
  })
}

// 积分购买列表
export function integralList() {
  return service({
    token: true,
    url: `/integral/list`,
    method: 'GET',
  })
}

// 购买积分(获得信息)
export function integralPayInfo(id) {
  return service({
    token: true,
    url: `/integral/payInfo/${id}`,
    method: 'GET',
  })
}

// 订单信息
export function orderInfo(orderNo) {
  return service({
    token: true,
    url: `/order/info//${orderNo}`,
    method: 'GET',
  })
}

// 订单分页
export function orderPage(current, size) {
  return service({
    token: true,
    url: `/order/page?current=${current}&size=${size}`,
    method: 'GET',
  })
}

// // 训练营分页
// export function campPage(current, size, type) {
//   return service({
//     token: true,
//     url: `/camp/page?current=${current}&size=${size}&type=${type}`,
//     method: 'GET',
//   })
// }

// 训练营列表
export function campList(type) {
  return service({
    token: true,
    url: `/camp/list?type=${type}`,
    method: 'GET',
  })
}

// 获得详情
export function campGet(id) {
  return service({
    token: true,
    url: `/camp/get/${id}`,
    method: 'GET',
  })
}

// 订阅知识库行业类型
export function userInfoSubscribeIndustryList() {
  return service({
    token: true,
    url: `/userInfo/subscribeIndustryList`,
    method: 'GET',
  })
}

// 轮播图
export function openBannar(type) {
  return service({
    url: `/open/bannar/${type}`,
    method: 'GET',
  })
}

// 解锁训练营(获得信息)
export function camPayInfo(id) {
  return service({
    token: true,
    url: `/camp/payInfo/${id}`,
    method: 'GET',
  })
}

// 获得训练营老师微信（解锁才会获得）
export function campGetWeixinCode(id) {
  return service({
    token: true,
    url: `/camp/getWeixinCode/${id}`,
    method: 'GET',
  })
}