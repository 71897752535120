<template>
  <div class="navigation-bar">
    <div class="content">
      <div class="left">
        <img class="logo" @click="$newRouter('/')" src="../assets/img/06.png" alt="" />
        <div class="search-box">
          <img class="search-icon" src="../assets/img/24.png" alt="" />
          <el-select clearable filterable remote v-model="search" :remote-method="remoteMethod" :loading="loading"
            @change="selectChange" default-first-option placeholder="请输入您要查询的岗位">
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
          <div class="search-btn">
            <el-button @click="searchBtn">搜一下</el-button>
          </div>
        </div>
      </div>
      <div class="training-camp-logo-title" @click="$newRouter('/trainingCamp')">
        <img src="../assets/img/41.png" alt="">
        <p>岗位工作坊</p>
      </div>
      <el-button class="login-btn" v-if="!userInfo.tokenValue" @click="$newRouter('/login')">登录</el-button>

      <el-dropdown v-else @command="handleCommand">
        <!-- <img :src="userInfo.avatar" alt="" /> -->
        <!-- <img class="user-mini2" src="../assets/img/user-mini2.png" alt=""> -->
        <img class="user-mini2" :src="userInfo.avatar" alt="">
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/" icon="el-icon-s-home">
            首页
          </el-dropdown-item>
          <el-dropdown-item command="/my" icon="el-icon-user">
            个人订阅
          </el-dropdown-item>
          <el-dropdown-item command="/my/order" icon="el-icon-coin">
            订单信息
          </el-dropdown-item>
          <el-dropdown-item command="/my/information" icon="el-icon-chat-line-round">
            个人消息
          </el-dropdown-item>
          <el-dropdown-item command="logout" icon="el-icon-switch-button">
            退出
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex'
  import { openProfessionPage } from '@/api/api'
  export default {
    data() {
      return {
        search: this.$store.state.searchKey,
        options: [],
        loading: false,
      }
    },
    computed: {
      ...mapState(['userInfo']),
    },
    created() {
      // 全局添加事件监听
      window.addEventListener('keyup', this.handleSearch)
    },
    mounted() {
      this.$watch("$store.state.searchKey", (v) => { this.search = v })
    },
    methods: {
      ...mapActions(['getUserInfo']),
      ...mapMutations(['remTokenInfo']),
      async openProfessionPage(searchKey) {
        const res = await openProfessionPage('', '', '', '', searchKey)
        this.options = res.data.records
        //console.log(searchKey)
      },
      remoteMethod(query) {
        if (query !== '') {
          this.search = query
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.openProfessionPage(query)
          }, 200);
        } else {
          this.options = [];
        }
      },
      selectChange(val) {
        this.$store.commit("setSearchKey", val)
        this.$newRouter('/second')
      },
      searchBtn() {
        this.$store.commit("setSearchKey", this.search)
        //console.log(22222,this.$store.state.searchKey)  
        this.$newRouter('/second')
      },
      handleCommand(command) {
        var tmp =''
        if(command === '/my')
        {
          tmp ='个人订阅'
        }
        else if(command === '/my/order')
        {
          tmp ='订单信息'
        }
        else if(command === '/my/information')
        {
          tmp ='个人消息'
        }
        var tmpobj = {
          name: tmp,
          url: ''
        }
        this.$store.commit("setNavInfo", { lev: 2, obj: tmpobj }) //处理面包屑导航
        if (command === 'logout') return this.logout()
        this.$newRouter(command)
      },
      logout() {
        this.$confirm('此操作将退出登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          this.remTokenInfo()
          this.$router.push('/')
        }).catch(() => {
        });
      },
      handleSearch(e) {
        if (this.search && e.keyCode == 13) {
          this.$newRouter('/second', { searchKey: this.search })
        }
      },
    }
  }
</script>

<style lang="less" scoped>
  .navigation-bar {
    width: 100%;
    height: 0.91rem;
    background-color: #fff;
    /* // position: fixed;
  // top: 0;
  // z-index: 9; */
    min-width: 14rem;
    box-sizing: border-box;

    /deep/ .content {
      width: 100%;
      height: 100%;
      padding-left: 2.67rem;
      padding-right: 2.57rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;

      .left {
        display: flex;
        align-items: center;

        .logo {
          width: 2.04rem;
          height: 0.28rem;
          margin-right: 2.47rem;
        }

        .el-select {
          height: .4rem;
        }

        .search-box {
          position: relative;
          display: flex;
          align-items: center;
          /* // margin-right: .78rem; */
          height: .4rem;

          .search-icon {
            position: absolute;
            top: 50%;
            left: 0.18rem;
            width: 0.16rem;
            height: 0.16rem;
            z-index: 1;
            transform: translate(0, -50%);
          }

          .el-input {
            width: 4.83rem;
            height: .4rem;
          }

          .el-input__inner {
            border-radius: 0.2rem;
            /* // border-color: rgba(238, 238, 238, 0.78); */
            background: rgba(238, 238, 238, 0.78);
            padding-left: 0.46rem;
            padding-right: 1rem;
            height: .4rem;
            font-size: .14rem;
            font-family: AlibabaPuHuiTiR;
            font-weight: 400;
          }

          .el-select__input {
            margin-left: .46rem;
            height: .4rem;
          }

          .search-btn {
            position: absolute;
            top: 0;
            right: 0px;
            z-index: 1;

            .el-button {
              width: 0.87rem;
              height: 0.4rem;
              background: #50c077;
              border-radius: 0.2rem;
              font-size: 0.14rem;
              font-family: AlibabaPuHuiTiB;
              font-weight: 400;
              color: #ffffff;
              padding: 0 !important;
            }

            .el-button:hover {
              background: #f7ac00;
            }
          }

        }
      }

      .login-btn {
        width: 0.84rem;
        height: 0.4rem;
        background: #ffffff;
        border: 0.01px solid #50c077;
        border-radius: 0.2rem;
        font-size: 0.14rem;
        font-family: AlibabaPuHuiTiM;
        font-weight: 400;
        color: #50c077;
      }

      .user-mini2 {
        width: .43rem;
        height: .43rem;
        cursor: pointer;
      }

      .training-camp-logo-title {
        cursor: pointer;
        /* // margin-right: 1.5rem; */
        margin: 0 .8rem;
        display: flex;
        align-items: center;
        color: #000;

        img {
          /* // width: 1rem; */
          height: .3rem;
          width: .3rem;
          margin-right: .05rem;
        }

        p {
          width: 1rem !important;
          min-width: 60px;
          padding-top: .05rem;
          font-size: .14rem;
          font-family: AlibabaPuHuiTiM;
          font-weight: 400;
        }
      }

      .training-camp-logo-title:hover {
        color: #50c077 !important;
      }
    }
  }
</style>