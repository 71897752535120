const myToken = 'my_token'

// 设置token
export const setToken = (newToken) => {
  sessionStorage.setItem(myToken, JSON.stringify(newToken))
}

// 获取token
export const getToken = () => {
  return JSON.parse(sessionStorage.getItem(myToken))
}

// 删除token
export const remToken = () => {
  sessionStorage.removeItem(myToken)
}