<template>
  <div class="home-content">
    <div class="slideshow">
      <el-carousel height="5.18rem" trigger="click">
        <el-carousel-item v-for="(item, index) in bannarList" :key="index">
          <img :src="item.picture" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home-content-box">
      <div class="occupation bx">
        <div class="text-background">
          <div class="background-text">NEWS</div>
          <div class="title-text">岗位介绍</div>
        </div>
        <div class="hint">
          岗位工作流程、工作内容、从事该岗位应具备的胜任技能及发展前景
        </div>
        <div class="list">
          <div class="box" v-for="item in occupationList" :key="item.id" @click="homeToIndustry(item.id,item.name)">
            <img class="img-main" :src="item.cover" alt="" />
            <img class="img-logo" src="../assets/img/hot.png" alt="">
            <div class="bottom">
              <div class="box-title">{{ item.name }}</div>
            <div class="text text-overflow2">
              {{
                item.introduction
              }}
            </div>
            <div class="list-item-button" @click="homeToIndustry(item.id,item.name)">查看详情</div>
            </div>
          </div>
        </div>
      </div>
      <div class="industry-intorduce">
        <div class="industry">
          <div class="industry-content bx">
            <div class="left">
              <div class="title">职业分类</div>
              <div class="text">
                无论您曾经有背景或兴趣爱好，岗位经验和专业的岗位技能训练营，都能使您快速获得工作能力和项目经验。
              </div>
              <div class="getAll" @click="secondBtn('all')">
                查看更多职业
              </div>
            </div>
            <div class="right">
              <div
                class="item"
                v-for="item in industryList"
                :key="item.id"
                @click="secondBtn(item.id,item.name)"
              >
                <div class="right-img"><img :src="item.icon" alt="" /></div>
                <div class="title">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="introduce bx">
          <div class="text-background">
          <div class="background-text">NEWS</div>
          <div class="title-text">用好YOU经验做什么？</div>
        </div>
          <div class="text">
            无论你是学生、老师、在职人员，都能在这里寻找提升自己的机会和知识
          </div>
          <div class="introduce1">
            <img class="img-left" src="../assets/img/19.png" alt="" />
            <div class="right">
              <img src="../assets/img/01.png" alt="" />
              <div class="introduce1-title">系统性学习专业技能</div>
              <div class="introduce1-title2">构建完整的职业知识体系</div>
              <div class="introduce1-text">
                一群有志分享，身处在全国各地，正在从事或者曾经从事你所要从事的职业的人，用<br />自己的学识和工作技能，帮你构建一个完成的职业知识体系。
              </div>
            </div>
          </div>
          <div class="border-bottom"></div>
          <div class="introduce2">
            <div class="left">
              <img src="../assets/img/02.png" alt="" />
              <div class="introduce2-title">全流程参与工作场景</div>
              <div class="introduce2-title2">提升职业岗位的胜任能力</div>
              <div class="introduce2-text">
                知识库服务是基于岗位工作过程，引入工作过程中的业务场景，以任务拆分的形式，<br />让用户参与其中，以达到提升岗位技能的目的。
              </div>
            </div>
            <div class="introduce2-right">
              <img src="../assets/img/20.png" alt="" />
            </div>
          </div>
          <div class="border-bottom"></div>
          <div class="introduce1">
            <img class="img-left" src="../assets/img/21.png" alt="" />
            <div class="right">
              <img src="../assets/img/03.png" alt="" />
              <div class="introduce1-title">针对性补齐课程短板</div>
              <div class="introduce1-title2">实现高等学历的提升目标</div>
              <div class="introduce1-text">
                由在某个课程学习上有所造诣或者有同样学习经历并成功上岸的人，经过专业、系统<br />的知识梳理，形成有效的学习经验，分享给你，实现学历提升的目标
              </div>
            </div>
          </div>
          <div class="border-bottom"></div>
          <div class="introduce2" style="padding-bottom: 0;">
            <div class="left">
              <img src="../assets/img/04.png" alt="" />
              <div class="introduce2-title">全流程参与工作场景</div>
              <div class="introduce2-title2">提升职业岗位的胜任能力</div>
              <div class="introduce2-text">
                知识库服务是基于岗位工作过程，引入工作过程中的业务场景，以任务拆分的形式，<br />让用户参与其中，以达到提升岗位技能的目的。
              </div>
            </div>
            <div class="introduce2-right">
              <img src="../assets/img/22.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { openRedProfession, openRedIndustry, openBannar } from '@/api/api'
export default {
  name: 'Home',
  data() {
    return {
      industryList: [],
      occupationList: [],
      bannarList: [],
    }
  },
  created() {
    this.openRedProfession()
    this.openRedIndustry()
    this.openBannar()
  },
  methods: {
    // 点击轮播图片跳转
    // bannarBtn(url) {
    //   // window.location.href = 'www.baidu.com'
    //   // location.href="http://www.baidu.com";
    //   window.open(url)
    // },
    // 轮播图
    async openBannar() {
      const res = await openBannar(0)
      this.bannarList = res.data
    },
    async openRedIndustry() {
      const res = await openRedIndustry()
      this.industryList = res.data
    },
    async openRedProfession() {
      const res = await openRedProfession()
      this.occupationList = res.data
    },
    homeToIndustry(id,val)
    {
      var tmp ={
        name:val,
        url:''
      }
      this.$store.commit("setNavInfo",{lev:2,obj:tmp}) //处理面包屑导航
      this.$store.commit("setProfessionPage",id) 
      this.$newRouter('/secondVideo')
    },
    secondBtn(id,val='行业分类') {
      // this.$router.push('/second')
      var tmp ={
        name:val,
        url:'/second'
      }
      this.$store.commit("setNavInfo",{lev:2,obj:tmp}) //处理面包屑导航
      this.$store.commit("setIndustryPage",{ac:id,pa:0,cl:0}) 
      this.$newRouter('/second')
      //sessionStorage.setItem('classifyId', id) // 行业Id
    },
    secondAllBtn() {
      sessionStorage.removeItem('classifyId')
      this.$newRouter('/second')
    }
  },
}
</script>

<style lang="less" scoped>
.home-content {
  background-color: #fff;
  .slideshow {
    position: relative;
    width: 100%;
    height: 5.18rem;
    img {
      width: 100%;
      height: 100%;
      /* cursor: pointer;*/
    }
    .slidesshow-btn {
      position: absolute;
      bottom: 1.04rem;
      left: 2.99rem;
      .el-button {
        width: 1.46rem;
        height: 0.38rem;
        background: rgba(0, 0, 0, 0);
        border: 0.01px solid rgba(255, 255, 255, 0.65);
        border-radius: 0.04rem;
        font-size: 0.14rem;
        font-family: AlibabaPuHuiTiR;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .home-content-box {
    padding-bottom: 1.56rem;
    .occupation {
      background-color: #fff;
      padding: 0.65rem 0 1.02rem 0;
      .hint {
        font-size: 0.18rem;
        font-family: AlibabaPuHuiTiR;
        font-weight: 400;
        color: #7c7f8a;
        margin-bottom: 0.39rem;
      }
      .list {
        display: flex;
        overflow: hidden;
        .box:nth-child(1) {
          margin-left: 0;
        }
        .box {
          position: relative;
          width: 3.3rem;
          margin-left: 0.27rem;
          height: 3.99rem;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          img {
            width: 3.3rem;
            height: 2.38rem;
            margin-bottom: 0.25rem;
          }
          .bottom {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .box-title {
            font-size: 0.2rem;
            font-family: AlibabaPuHuiTiB;
            font-weight: 400;
            color: #242424;
            /* // margin-bottom: 0.16rem; */
          }
          .text {
            font-size: 0.14rem;
            font-family: AlibabaPuHuiTiR;
            font-weight: 400;
            color: #9fa1ad;
            line-height: 0.18rem;
            margin-bottom: 0.22rem;
            padding-right: 0.25rem;
            line-height: 0.18rem;
            word-wrap:break-word; 
            word-break:break-all;
          }
          .list-item-button {
            width: 0.98rem;
            height: 0.36rem;
            min-width: 50px;
            background: #50c077;
            border-radius: 0.04rem;
            font-size: 0.14rem;
            font-family: AlibabaPuHuiTiR;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .list-item-button:hover{
            background: #f7ac00;
          }
          }
          .img-logo {
            width: .6rem;
            height: .24rem;
            position: absolute;
            top: .24rem;
            left: .23rem;
          }
        }
        .box:hover .img-main{
          transform: scale(1.02);
        }
      }
    }
  }
  .industry-intorduce {
    background-color: #fff;
    .industry {
      width: 100%;
      height: 8.09rem;
      box-sizing: border-box;
      background: linear-gradient(to bottom, #f1f2f3, #fefefe);
      padding: 1.04rem 0 0 0;
      .industry-content {        
        display: flex;
        .left {
          min-width: 155px;
          width: 3.58rem;
          height: 2.89rem;
          background: #50c077;
          border-radius: 0.1rem;
          padding: 0.5rem 0.56rem 0.49rem 0.48rem;
          box-sizing: border-box;
          .title {
            height: .39rem;
            font-size: 0.4rem;
            font-family: AlibabaPuHuiTiB;
            font-weight: 400;
            margin-bottom: 0.25rem;
            color: #ffffff;
          }
          .text {
            font-size: 0.16rem;
            font-family: AlibabaPuHuiTiR;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.24rem;
            opacity: 0.68;
            min-width: 119px;
            /* margin-bottom: 0.6rem;
            height: .41rem; */
          }
          .getAll {
            width: 2rem;
            padding: 0 .2rem;
            height: 0.34rem;
            margin-top: 0.1rem;
            background: rgba(0, 0, 0, 0);
            border: 0.01px solid rgba(255, 255, 255, 0.65);
            border-radius: 0.04rem;
            font-size: 0.14rem;
            font-family: AlibabaPuHuiTiR;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .getAll:hover{
            background: #f7ac00;
          }
        }
        .left:hover{
          transform: scale(1.02);
        }
        .right {
          min-width: 572px;
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          overflow: hidden;
          .item {
            width: 2.35rem;
            height: 1.32rem;
            min-width: 130px;
            background: #ffffff;
            border-radius: 0.1rem;
            box-sizing: border-box;
            padding: 0.2rem 0 0.25rem 0.28rem;
            margin-left: 0.2rem;
            margin-bottom: 0.25rem;
            cursor: pointer;
            .right-img {
              width: 0.56rem;
              height: 0.56rem;
              margin-bottom: 0.13rem;
              /* // box-sizing: border-box;
              // border: .01rem dashed #bababa; */
            }
            img {
              width: 100%;
              height: 100%;
            }
            .title {
              font-size: 0.18rem;
              font-family: AlibabaPuHuiTiM;
              font-weight: 400;
              color: #323232;
            }
          }
          .item:hover{
            transform: scale(1.05);
          }
        }
      }
    }
    .introduce {
      margin-top: -3rem;
      // padding-left: 2.53rem;
      // padding-right: 2.92rem;
      // width: 100%;
      .title {
        font-size: 0.4rem;
        font-family: AlibabaPuHuiTiB;
        font-weight: 400;
        color: #030303;
        padding-left: 0.07rem;
        margin-bottom: 0.27rem;
      }
      .text {
        font-size: 0.18rem;
        font-family: AlibabaPuHuiTiR;
        font-weight: 400;
        color: #7c7f8a;
        padding-left: 0.1rem;
        margin-bottom: 0.32rem;
      }
      .introduce1 {
        display: flex;
        padding-bottom: 0.5rem;
        .img-left {
          width: 5.6rem;
          height: 4.2rem;
          margin-right: 0.98rem;
        }
        .right {
          position: relative;
          padding-top: 1.36rem;
          img {
            position: absolute;
            top: 0.6rem;
            left: 0;
            width: 1.2rem;
            height: 1.2rem;
          }
          .introduce1-title {
            font-size: 0.4rem;
            font-family: AlibabaPuHuiTiM;
            font-weight: 400;
            color: #030303;
            margin-bottom: 0.13rem;
          }
          .introduce1-title2 {
            font-size: 0.5rem;
            font-family: AlibabaPuHuiTiL;
            font-weight: 400;
            color: #030303;
            margin-bottom: 0.26rem;
          }
          .introduce1-text {
            font-size: 0.18rem;
            font-family: AlibabaPuHuiTiR;
            font-weight: 400;
            color: #030303;
            line-height: 0.32rem;
          }
        }
      }
      .border-bottom {
        width: 100%;
        height: 0.01rem;
        background: #ededed;
      }
      .introduce2 {
        display: flex;
        padding-bottom: 0.64rem;
        .left {
          position: relative;
          padding-top: 1.63rem;
          padding-left: 0.24rem;
          margin-right: 1.01rem;
          img {
            position: absolute;
            top: 0.89rem;
            left: 0.22rem;
            width: 1.2rem;
            height: 1.2rem;
          }
          .introduce2-title {
            font-size: 0.4rem;
            font-family: AlibabaPuHuiTiM;
            font-weight: 400;
            color: #030303;
            margin-bottom: 0.13rem;
          }
          .introduce2-title2 {
            font-size: 0.5rem;
            font-family: AlibabaPuHuiTiL;
            font-weight: 400;
            color: #030303;
            margin-bottom: 0.26rem;
          }
          .introduce2-text {
            padding-left: 0.04rem;
            font-size: 0.18rem;
            font-family: AlibabaPuHuiTiR;
            font-weight: 400;
            color: #030303;
            line-height: 0.32rem;
          }
        }
        .introduce2-right {
          padding-top: 0.21rem;
          img {
            width: 5.6rem;
            height: 4.2rem;
          }
        }
      }
    }
  }
  .text-background {
    position: relative;
    margin-bottom: .15rem;
    // height: 0.61rem;
.background-text {
  height: 0.61rem;
  // position: absolute;
  // top: 0;
  // left: 0;
  font-size: 0.85rem;
font-family: AlibabaPuHuiTiB;
font-weight: 400;
color: #E6E9F3;
opacity: 0.35;
height: 100%;
line-height: .85rem;
}
    .title-text {
        font-size: 0.4rem;
        font-family: AlibabaPuHuiTiB;
        font-weight: 400;
        color: #030303;
        // margin-bottom: 0.28rem;
        position: absolute;
        top: 50%;
        left: .02rem;
        transform: translate(0, -50%);
    }
  }
}
</style>
