import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)



const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/second',
    name: 'second',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Second.vue')
  },
  {
    path: '/secondDetails',
    name: 'secondDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/secondDetails.vue')
  },
  {
    path: '/secondVideo',
    name: 'secondVideo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/second-video.vue')
  },
  {
    path: '/comprehension',
    name: 'comprehension',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/comprehension.vue'),
  },
  {
    path: '/my',
    name: 'my',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/my/my.vue'),
    children: [
      {
        path: '/my',
        name: 'occupation',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/my/occupation.vue'
          ),
      },
      {
        path: '/my/information',
        name: 'information',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/my/information.vue'
          ),
      },
      {
        path: '/my/order',
        name: 'order',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '@/views/my/order.vue'
          ),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/login.vue')
  },
  {
    path: '/trainingCamp',
    name: 'trainingCamp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/training-camp.vue')
  },

  {
    path: '/trainingCampDetails',
    name: 'trainingCampDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/training-camp-details.vue')
  },
  {
    path: '/error',
    name: 'error',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/error.vue')
  },
  
]

const router = new VueRouter({
  routes
})

export default router
