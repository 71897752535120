<template>
  <!-- :style="$route.path !== '/login' ? 'padding-top: .91rem;' : ''" -->
  <div id="app">
    <navigation-bar v-if="$route.path !== '/login'"></navigation-bar>
    <router-view class="router-view" v-if="isRouterAlive"></router-view>
    <footer-bar v-if="$route.path !== '/login'"></footer-bar>
    <el-dialog title="微信扫码登录" :visible.sync="wxLoginDialogVisible" width="4.5rem" :before-close="handleClose">
      <wx-login></wx-login>
    </el-dialog>
    <el-backtop :bottom="200" :visibility-height="500"></el-backtop>
  </div>
</template>
<script>
  import NavigationBar from '@/components/navigation-bar.vue'
  import FooterBar from '@/components/footer.vue'
  import WxLogin from '@/components/wx-login.vue'
  import { mapState, mapMutations } from 'vuex'
  export default {
    components: {
      NavigationBar,
      FooterBar,
      WxLogin
    },
   
    computed: {
      ...mapState(['wxLoginDialogVisible']),
    },
    provide() {  // 在祖先组件中通过 provide 提供变量
      return {
        reload: this.reload  //  声明一个变量
      }
    },
    data() {
      return {
        isRouterAlive: true  // 控制 router-view 是否显示达到刷新效果
      }
    },
    methods: {
      ...mapMutations(['setWxLoginDialogVisible']),
      handleClose() {
        this.setWxLoginDialogVisible(false)
      },
      // provide中声明的变量
      reload() {
        // 通过 this.isRouterAlive 控制 router-view 达到刷新效果
        this.isRouterAlive = false
        this.$nextTick(function () {
          this.isRouterAlive = true
        })
      }
    }
  }
</script>
<style lang="less">
  #app {
    height: 100%;
    display: flex;
    flex-direction: column;

    /* zoom: 2; */
    .router-view {
      flex: 1;
    }

    .el-dialog__body {
      padding: 0 0 !important;
    }
  }
</style>