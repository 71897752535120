<template>
    <div class="login-box">
        <div class="hint">
          <img v-show="loginType == 0" src="@/assets/img/user-mini.png" alt="" />
          <img
            v-show="loginType == 1"
            style="width: 0.17rem; height: 0.14rem;"
            src="@/assets/img/code.png"
            alt=""
          />
          <span>{{ loginType == 0 ? '切换账号登录' : '扫码快速登录' }}</span>
        </div>
        <div class="right-logo" @click="loginTypeBtn">
          <img v-show="loginType == 0" src="@/assets/img/pc.png" alt="" />
          <img v-show="loginType == 1" src="@/assets/img/qr.png" alt="" />
        </div>
        <div v-show="loginType == 0" class="qr-code-content">
          <div class="qr-title">微信扫描二维码登录</div>
          <div class="qr-text">请及时扫码登录，过期请刷新</div>
          <div v-if="renderUrl" class="qr-code">
            <iframe
              class="qr-code"
              scrolling="no"
              :src="
                renderUrl +
                '&self_redirect=true' +
                '&href=data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEwMCU7bWFyZ2luLXRvcDogMDtib3JkZXI6MH0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAxMDAlO2Rpc3BsYXk6IGZsZXg7anVzdGlmeS1jb250ZW50OiBjZW50ZXI7fQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6bm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO3BhZGRpbmc6MH0KLmltcG93ZXJCb3ggLmluZm97bWFyZ2luOiAtMTBweCBhdXRvfQpodG1sIHtjb2xvcjogI2ZmZn0='
              "
            >
            </iframe>
          </div>
        </div>
        <div v-show="loginType == 1" class="phone-password-content">
          <!-- <div class="title">欢迎登录</div> -->
          <el-tabs v-model="activeName">
            <el-tab-pane label="手机验证" name="first">
              <el-form :model="phoneData">
                <el-form-item>
                  <div class="phone-input">
                    <div class="left">
                      <img src="/img/phone-mini.png" alt="" />
                      <span>手机号</span>
                    </div>
                    <div class="phone-input-right-text">
                      <el-input
                        placeholder="请输入手机号"
                        v-model="phoneData.phone"
                      ></el-input>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="phone-input">
                    <div class="left">
                      <img
                        style="width: 0.13rem; height: 0.15rem;"
                        src="/img/sms.png"
                        alt=""
                      />
                      <span>验证码</span>
                    </div>
                    <el-input v-model="phoneData.code"></el-input>
                    <div class="get-code" v-if="twocodeflag" @click="sendcodes">
                      {{ twocomm }}
                    </div>
                    <div class="get-code" v-else>{{ twocomm + 'S' }}</div>
                  </div>
                </el-form-item>
              </el-form>
              <!-- <div class="forget-password">
                <el-checkbox v-model="phoneData.checked"
                  ><span>自动登录</span></el-checkbox
                >
                <div class="forget-password-text">忘记密码？</div>
              </div> -->
              <div class="btn">
                <el-button @click="phoneLogin">登录</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="密码登录" name="second">
              <el-form :model="passwordData">
                <el-form-item>
                  <div class="phone-input">
                    <div class="left">
                      <img src="/img/user-mini.png" alt="" />
                      <span>账号</span>
                    </div>
                    <div class="phone-input-right-text">
                      <el-input
                        placeholder="请输入账号"
                        v-model="passwordData.user"
                      ></el-input>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="phone-input">
                    <div class="left">
                      <img
                        style="width: 0.13rem; height: 0.15rem;"
                        src="/img/password-mini.png"
                        alt=""
                      />
                      <span>密码</span>
                    </div>
                    <div class="phone-input-right-text">
                      <el-input
                        placeholder="请输入密码"
                        v-model="passwordData.password"
                        show-password
                      ></el-input>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
              <div class="forget-password">
                <el-checkbox v-model="passwordData.checked"
                  ><span>自动登录</span></el-checkbox
                >
                <div class="forget-password-text">忘记密码？</div>
              </div>
              <div class="btn">
                <el-button @click="passwordLogin">登录</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
</template>

<script>
import { loginapprcode, sendcode, loginPhone,loginPassword } from '@/api/api'
import { mapMutations } from 'vuex'
export default {
  inject:['reload'], // 使用 inject 注入 reload 变量
  data() {
    return {
      loginType: 0, // 0微信登录 1手机验证码、密码登录
      activeName: 'first',
      phoneData: {
        phone: '',
        code: '',
      },
      passwordData: {
        user: '',
        password: '',
        checked: false,
      },
      renderUrl: '',
      uuid: '',
      twocomm: '获取验证码',
      twocodeflag: true,
      redirect: undefined,
    }
  },
  // watch: {
  //   $route: {
  //     handler: function (route) {
  //       if (route.query.redirect !== undefined) {
  //         this.redirect = route.query && route.query.redirect
  //       }
  //       // var sessionKeys = Object.keys(sessionStorage)
  //       // if (sessionKeys.length !== 0) {
  //       //   this.redirect = ''
  //       //   for (var i = 0; i < sessionKeys.length; i++) {
  //       //     //可以对key 进行分析从而决定是否要删除sessionStorage 里的缓存
  //       //     this.redirect += sessionKeys[i] + '=' + sessionStorage.getItem(sessionKeys[i]) + (i !== sessionKeys.length - 1 ? '&' : '')
  //       //   }
  //       // }
  //     },
  //     immediate: true,
  //   },
  // },
  created() {
    //获取二维码
    this.getcodere()
    //扫码登录之后，监听响应数据
    window.addEventListener(
      'message',
      (e) => {
        if (e.data.state == 3) {
          this.$message.warning('登录失败,请刷新重试!')
        } else if (e.data.state == 2) {
          // console.log(e.data)
          this.uuid = e.data.uuid
          this.loginType = 1
          // this.authphone = true
        } else if (e.data.state == 1) {
          this.setTokenInfo({
            ...e.data.loginInfo.userInfo,
            ...e.data.loginInfo.tokenInfo,
          })
          if(this.$route.path === '/login') return this.$router.push('/')
          this.setWxLoginDialogVisible(false)
          return this.reload();  // 刷新页面
        }
      },
      false
    )
  },
  methods: {
    ...mapMutations(['setTokenInfo', 'setWxLoginDialogVisible']),
    loginTypeBtn() {
      this.loginType = this.loginType === 0 ? 1 : 0
    },
    //刷新二维码
    getcodere() {
      loginapprcode('weixin').then((res) => {
        this.renderUrl = res.data
      })
    },
    // 发送手机验证码
    sendcodes() {
      if (!this.phoneData.phone) return this.$message.warning('请输入手机号')
      //sendcode(this.uuid, this.phoneData.phone).then(() => {
      sendcode(this.phoneData.phone).then((res) => {   
        if (res.code === -100) return this.$message.warning('手机号未注册')
        else if (res.code !== 200) return this.$message.warning('请输入正确的手机号')     
        var that = this
        that.twocomm = 60
        that.twocodeflag = false
        var time = setInterval(() => {
          that.twocomm--
          if (that.twocomm == 0) {
            that.twocomm = '重新发送'
            that.twocodeflag = true
            clearInterval(time)
          }
        }, 1000)
      })
    },
    //绑定第三方登录手机
    phoneLogin() {
      if (!this.phoneData.phone) return this.$message.warning('请输入手机号')
      if (!this.phoneData.code) return this.$message.warning('请输入验证码')
      loginPhone(this.uuid, this.phoneData.phone, this.phoneData.code).then(
        (res) => {
          this.uuid = ''
          this.phoneData.phone = ''
          this.phoneData.code = ''
          this.setTokenInfo({ ...res.data.userInfo, ...res.data.tokenInfo })
          if(this.$route.path === '/login') return this.$router.push('/')
          this.setWxLoginDialogVisible(false)
          return this.reload();  // 刷新页面
        }
      )
    },
     //手机号密码登录 2023-7-9 yinq
     passwordLogin() {
      if (!this.passwordData.user) return this.$message.warning('请输入手机号')
      if (!this.passwordData.password) return this.$message.warning('请输入密码')
      var passwordInfoData = {}
      passwordInfoData.username=this.passwordData.user
      passwordInfoData.password=this.passwordData.password
      loginPassword(passwordInfoData).then(
        (res) => {
          this.passwordData.user = ''
          this.passwordData.password = ''
          this.setTokenInfo({ ...res.data.userInfo, ...res.data.tokenInfo })
          //console.log(res)
          if(this.$route.path === '/login') return this.$router.push('/')
          // this.setWxLoginDialogVisible(false)
          return this.reload();  // 刷新页面
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
    .login-box {
      width: 4.2rem;
      height: 4.8rem;
      background: #ffffff;
      border-radius: 0.04rem;
      box-sizing: border-box;
      padding: 0.6rem 0.64rem 0.6rem 0.64rem;
      position: relative;
      .hint {
        position: absolute;
        right: 0.71rem;
        top: 0.26rem;
        width: 1.41rem;
        height: 0.3rem;
        background: #e6fbf7;
        border-radius: 0.02rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.14rem;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #03c678;
        cursor: pointer;
        img {
          width: 0.14rem;
          height: 0.15rem;
          margin-right: 0.08rem;
        }
      }
      .right-logo {
        position: absolute;
        top: 0.21rem;
        right: 0.2rem;
        cursor: pointer;
        img {
          width: 0.44rem;
          height: 0.42rem;
        }
      }
      .qr-code-content {
        padding-top: 0.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .qr-title {
          font-size: 0.24rem;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #174037;
          margin-bottom: 0.17rem;
        }
        .qr-text {
          font-size: 0.14rem;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #94a39d;
          margin-bottom: 0.12rem;
        }
        .qr-code {
          width: 2.21rem;
          height: 2.81rem;
          box-sizing: border-box;
          background-color: #fff;
          border: 0.01rem solid #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      /deep/ .phone-password-content {
        .title {
          font-size: 0.28rem;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #174037;
          margin-bottom: 0.1rem;
        }
        .el-tabs__nav-wrap::after {
          height: 0 !important;
        }
        .el-form-item {
          margin-bottom: 0.28rem;
        }
        .phone-input {
          position: relative;
          display: flex;
          .left {
            position: absolute;
            top: 0;
            left: 0.18rem;
            z-index: 1;
            display: flex;
            align-items: center;
            img {
              width: 0.12rem;
              height: 0.16rem;
              margin-right: 0.1rem;
            }
            span {
              font-size: 0.16rem;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              color: #494949;
            }
          }
          .get-code {
            position: absolute;
            top: 0;
            right: 0.2rem;
            font-size: 0.14rem;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #03c678;
            z-index: 1;
            cursor: pointer;
          }
          .el-input__inner {
            padding-left: 1.1rem;
          }
          .phone-input-right-text {
            .el-input__inner {
              text-align: right;
            }
          }
        }
        .forget-password {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.34rem;
          span {
            font-size: 0.14rem;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
          }
          .forget-password-text {
            font-size: 0.14rem;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #969696;
            cursor: pointer;
          }
          .forget-password-text:hover {
            color: #03c678;
          }
        }
        .btn {
          text-align: center;
          padding-top: 0.2rem;
          .el-button {
            width: 100%;
            height: 0.5rem;
            background: linear-gradient(270deg, #03c678, #1cd477);
            border-radius: 0.04rem;
            font-size: 0.16rem;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .el-tabs__item {
          font-size: 0.18rem !important;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          height: 0.35rem !important;
          color: #969696;
          margin-bottom: 0.05rem !important;
          padding: 0 0.13rem !important;
        }
        .el-tabs__item:nth-child(2) {
          padding-left: 0 !important;
        }
        .el-tabs__item.is-active {
          font-size: 0.18rem !important;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #000000;
        }
        .el-tabs__header {
          margin-bottom: 0.3rem !important;
        }
        .el-tabs__nav-wrap.is-scrollable {
          padding: 0;
        }
      }
    }
</style>