<template>
  <div class="footer">
    <div class="footer-content">
      <div class="top">
        <div class="left"  style="margin-right: .3rem">
          <img src="../assets/img/05.png" alt="" />
          <div class="footer-logo-text">
            <p>长沙好有经验信息技术服务有限公司版权所有</p>
            <p>ICP备案：湘ICP备2021021145号-2</p>
          </div>
        </div>
        <div class="middle">
          <div class="item" style="margin-right: .3rem">
            <div class="title">联系我们</div>
            <div class="text">邮箱：haoyoujingyan@163.com</div>
          </div>
        </div>
        <div class="middle">
          <div class="item" style="margin-right: .3rem">
            <div class="title">公司地址</div>
            <div class="text">湖南长沙市岳麓区学士街道</div>
            <div class="text">学士路389号海普众包园2号栋3楼A区3A01</div>
          </div>
        </div>
        <div class="right">
          <div class="item" style="margin-right: .2rem">
            <img src="../assets/img/11.png" alt="">
            <div class="title" style="padding: .1rem 0px 0px .4rem;">服务号</div>
            
          </div>
          <div class="item">
            <img src="../assets/img/202.png" alt="">
            <div class="title" style="padding: .1rem 0px 0px .4rem;">订阅号</div>
            
          </div>
        </div>
      </div>
      <div class="bottom">
        <p>长沙好有经验信息技术服务有限公司 Copyright  2018-<span>{{ isYear }}</span> All rights reserved</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
    return {
      isYear:new Date().getFullYear(),
    }
  },

  }
</script>

<style lang="less" scoped>
  .footer {
    box-sizing: border-box;
    background-color: #282b2e;
    /* // height: 2.62rem; */
    width: 100%;
    min-width: 14rem;

    .footer-content {
      padding: 0.55rem 2.6rem 0 2.62rem;

      .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 0.01rem solid #3c4651;
        /* padding-bottom: 0.3rem; */

        .left {
          img {
            width: 2.04rem;
            height: 0.28rem;
            margin-bottom: 0.26rem;
          }

          .footer-logo-text {
            font-size: 0.12rem;
            font-family: AlibabaPuHuiTiR;
            font-weight: 400;
            color: #ffffff;
            line-height: 0.25rem;
            opacity: 0.55;
          }
        }

        .middle {
          padding-top: 0.07rem;
          margin-left: 0.1rem;
          .item {
            .title {
              font-size: 0.14rem;
              font-family: AlibabaPuHuiTiM;
              font-weight: 400;
              color: #ffffff;
              margin-bottom: .33rem;
            }

            .text {
              font-size: 0.12rem;
              font-family: AlibabaPuHuiTiR;
              font-weight: 400;
              color: #ffffff;
              margin-bottom: .1rem;
            }
          }
        }

        .right {
          padding-top: 0.07rem;
          /* padding-right: 0.34rem; */
          display: flex;

          .item {
            .title {
              font-size: 0.14rem;
              font-family: AlibabaPuHuiTiM;
              font-weight: 400;
              color: #ffffff;
              margin-bottom: .33rem;
            }

            img {
              width: 1.2rem;
              height: 1.2rem;
            }
            img:hover{
              transition: all 0.5s;
              transform: scale(2);
            }
          }
        }
      }

      .bottom {
        padding: 0.075rem 0;
        text-align: center;
        font-size: 0.14rem;
        font-family: AlibabaPuHuiTiR;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
</style>