import Vue from 'vue'
import Vuex from 'vuex'
import { setToken, getToken, remToken } from '../store/token'
import { getUserInfo } from '@/api/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    searchKey: "",//搜索内容
    userInfo: getToken() || {},//登录用户信息
    wxLoginDialogVisible: false, // 微信扫码登录弹框
    navInfo: { //控制面包屑导航
      route: [{ name: '首页', url: '/' }],
      pageLevel: 1
    },
    industryPage: { //行业分类状态
      active: 'all',
      classificationActive: 1,
      page: 1
    },
    professionPage: { //岗位页面
      professionID: ''
    },
    knowledgePage: { //知识库页面
      activeName: 'first',
      activeModule: [],
      knowledgeID: ''
    },
    articlePage:{//文章页面
      articleID:0,
      moduleID:0,
      articleIdActive:'',
      activeModule:[]
    },
    subPage: { //个人中心订阅页面
      active: 'all',
      page: 1
    },
    traCampPage: {//训练营页面
      activeTabsName: 'first'
    },
  },
  mutations: {
    // setTraCampPage(state, val) {
    //   state.traCampPage.activeTabsName = val
    // },
    setSubPage(state, obj) {
      if (obj.ac != '') {
        state.subPage.active = obj.ac
      }
      if (obj.pa != 0) {
        state.subPage.page = obj.pa
      }
    },
    setArticlePage(state, obj) {      
      if (obj.aid > 0) {
        state.articlePage.articleID = obj.aid
      }
      if (obj.mid > 0) {
        state.articlePage.moduleID = obj.mid
      }
      if (obj.active != '') {
        state.articlePage.articleIdActive = obj.active
      }
      // if (obj.ms.length === 0) {
      //   state.articlePage.activeModule = []
      // }
    },
    setKnowledgePage(state, obj) {      
      if (obj.name != '') {
        state.knowledgePage.activeName = obj.name
      }
      if (obj.id != 0) {
        state.knowledgePage.knowledgeID = obj.id
      }
      // if (mo.length > 0) {
      //   state.knowledgePage.activeModule = mo
      // }
    },
    setProfessionPage(state, val) {
      state.professionPage.professionID = val
    },
    setIndustryPage(state, val) {
      if (val.ac != '') {
        state.industryPage.active = val.ac
      }
      if (val.cl != 0) {
        state.industryPage.classificationActive = val.cl
      }
      if (val.pa != 0) {
        state.industryPage.page = val.pa
      }

    },
    setNavInfo(state, obj) {
      
      var tmp = 1
      if (obj.lev > 1) {
        tmp = obj.lev - 1
        state.navInfo.pageLevel = obj.lev
        state.navInfo.route.splice(tmp)
        state.navInfo.route.push(obj.obj)
      }
      else {
        state.navInfo.pageLevel = obj.lev
        state.navInfo.route.splice(tmp)
      }
      //console.log("面包屑导航",state.navInfo.route)
    },
    setWxLoginDialogVisible(state, newData) {
      state.wxLoginDialogVisible = newData
    },
    setTokenInfo(state, newSetToken) {
      state.userInfo = newSetToken
      state.wxLoginDialogVisible = false  // 微信扫码登录弹框
      setToken(newSetToken)
    },
    remTokenInfo(state) {
      state.userInfo = {}
      // sessionStorage.clear()
      remToken()
    },
    setSearchKey(state, val) {
      state.searchKey = val

    },
  },
  actions: {
    async getUserInfo(context, payload) {
      const res = await getUserInfo()
      const userInfo = { ...context.state.userInfo, ...res.data }
      context.commit('setTokenInfo', userInfo)
    }
  },
  modules: {
  }
})
