import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store/index'
import router from "@/router";

// 定义 loading
let url = '/api/user'
// if(window.location.hostname =="localhost"){
// var url='https://test.api.hk-wallet.com'
// }else{
// var url=''
// }
const service = axios.create({
  baseURL: url, // url = base url + request url
  timeout: 60000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // 请求加上token
    if (store.state.userInfo.tokenValue && config.token) {
      config.headers['user'] = 'Bearer ' + store.state.userInfo.tokenValue
    }
    //加上请求数据类型
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  (error) => {
    //console.log("request", error) // for debug
    router.push(`/error`)
    // return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    // handleResponseLoading()
    const res = response.data
    //console.log("responsesucsse", res)
    if (res.code === -100) {
      return res
    }
    else if (res.code !== 200) {
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000,
      // })
      //return Promise.reject(new Error(res.message || 'Error'))
      //return Message().$message.warning(res.message)

      // if(res.code ===424){
      //   Message({
      //     message: store.state.userInfo.token ? '登录过期，请重新登录' : '请登录',
      //     type: 'error',
      //     duration: 5 * 1000,
      //   })
      //   store.commit('remTokenInfo')
      //   store.commit('setWxLoginDialogVisible', true)
      //   // router.push(`/login?redirect=${router.currentRoute.fullPath}`)
      //   return res
      // }
      // Message().$message.warning(res.message)
      return res

    }
    else {
      return res
    }
  },
  (error) => {
    //console.log("responseerror", error) // for debug
    router.push(`/error`)
    // if (error.response.data.code === 424) {
    //   // Message({
    //   //   message: store.state.userInfo.token ? '登录过期，请重新登录' : '请登录',
    //   //   type: 'error',
    //   //   duration: 5 * 1000,
    //   // })
    //   store.commit('remTokenInfo')
    //   store.commit('setWxLoginDialogVisible', true)
    //   //// router.push(`/login?redirect=${router.currentRoute.fullPath}`)
    //   //return Promise.reject(error)

    // }
    // else {
    //   this.$newRouter(`/error`)
    // }
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000,
    // })
    // return Promise.reject(error)
  }
)

export default service