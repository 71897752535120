import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/common/index.css'
import '@/assets/font/font.css'
import '@/assets/element-fonts/index.css'
import './router/permission'
import formatTime from './common/fileters'

Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.formatTime = formatTime

Vue.prototype.$bus = new Vue()
// 封装跳转打开新页面方法 挂载到vue上
Vue.prototype.$newRouter = function (path, query) {
  const routeUrl = router.resolve({
    path: path,
    query
  })
  //window.open(routeUrl.href, '_blank')
  window.open(routeUrl.href, '_self')
}
//Vue统一错误处理
Vue.config.errorHandler = function(err) {
  console.log("global", err);
  message.error("出错了");
};

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    setRemPc();
    window.addEventListener('resize', setRemPc);//浏览器窗口大小改变时调用rem换算方法

    //通过监听beforeunload事件来进行数据的sessionStorage存储,解决vuex刷新后数据丢失
    if (window.sessionStorage.getItem("list")) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(window.sessionStorage.getItem("list"))))
    }
    window.addEventListener("beforeunload", () => {
      window.sessionStorage.setItem("list", JSON.stringify(this.$store.state))
    })
  },
}).$mount('#app')

//rem计算
function setRemPc() {
  var width = 100/4096; //表示大于4096的设计图,使用150PX的默认值
  var width0 = 100/2560; //表示大于2560的设计图,使用150PX的默认值
  var width1 = 100 / 1920;// 表示1920的设计图,使用100PX的默认值
  var width2 = 100 / 1000;// 最小1400
  var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
  // console.log("当前窗口的宽度", bodyWidth,"默认值width1",width1)
  if (bodyWidth > 1920) { // rem适配 屏幕缩放太大或太小会错乱 加个判断在多少尺寸之内才会变化
    var rem0 = bodyWidth * width0;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    document.getElementsByTagName('html')[0].style.fontSize = rem0 + 'px';
  }
  else if (bodyWidth > 700 && bodyWidth <= 1920) { // rem适配 屏幕缩放太大或太小会错乱 加个判断在多少尺寸之内才会变化
    var rem1 = bodyWidth * width1;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    document.getElementsByTagName('html')[0].style.fontSize = rem1 + 'px';
  } else {
    var rem2 = 500 * width2
    document.getElementsByTagName('html')[0].style.fontSize = rem2 + 'px'; // 如果不在判断尺寸之内就设置为基础值, 这个基础值100对应第一个100
  }
}